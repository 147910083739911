/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';

// REFERENCE: https://router.vuejs.org/guide/advanced/lazy-loading.html
/**
 * NOTE: For every new webpackChunkName, that chunk name should be added to `LazyScript.vue` file
 */

// TODO: remove home from common view as it is for guest user
// Common Home (Public only)
const LandingPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home" */
  '@/views/Common/LandingPage.vue'
);

const DownloadPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "download-page" */
  '@/views/Common/DownloadPage.vue'
);
const ContactPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "contact" */
  '@/views/Common/ContactPage.vue'
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: DownloadPage,
    meta: {
      title: 'Download',
    },
  },
  {
    path: '/contact',
    name: 'Contact Developers',
    component: ContactPage,
    meta: {
      title: 'Contact our developers',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            resolve();
          } else {
            resolve({
              top: 0,
            });
          }
        }, 500);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

export { routes };

export default router;
