import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-poppins", {
      'bg-[#1E1E1E]': $setup.route.path === '/',
      'bg-white': $setup.route.path !== '/',
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.route.path !== '/')
        ? (_openBlock(), _createBlock($setup["BlueMargin"], { key: 0 }))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Header navigation "),
      ($setup.route.path !== '/')
        ? (_openBlock(), _createBlock($setup["HeaderNav"], { key: 1 }))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["w-screen", { 'min-h-screen pl-20 pt-[84px] md:pl-25 md:pt-28 lg:pl-[110px] lg:pt-30': $setup.route.path !== '/' }])
      }, [
        _createVNode(_component_RouterView)
      ], 2 /* CLASS */)
    ])
  ], 2 /* CLASS */))
}