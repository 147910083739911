<template>
  <!-- 2 underlines -->
  <div class="absolute left-0 top-0 ml-20 h-full w-1 bg-[#00A1E0] md:ml-25 lg:ml-[110px]" />
  <div class="absolute left-0 top-0 mt-[84px] h-1 w-screen bg-[#00A1E0] md:mt-28 lg:mt-30" />
  <!-- intersection and intersecting divs -->

  <!-- intersection -->
  <div class="absolute left-0 top-0 h-[84px] w-20 bg-[#00A1E0]/[58%] md:h-28 md:w-25 lg:h-30 lg:w-[110px]" />
  <!-- other 2 divs -->
  <div
    class="absolute left-20 top-0 h-[84px] w-[calc(100vw-80px)] bg-[#00A1E0]/[29%] md:left-25 md:h-28
        md:w-[calc(100vw-100px)] lg:left-[110px] lg:h-30 lg:w-[calc(100vw-110px)]"
  />
  <div
    class="absolute left-0 top-[84px] h-[calc(100%-84px)] w-[80px] bg-[#00A1E0]/[29%] md:top-28
        md:h-[calc(100%-112px)] md:w-[100px] lg:top-30 lg:h-[calc(100%-120px)] lg:w-[110px]"
  />
</template>
