import {
  createApp,
} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import '@/style/css/index.css';
import '@/style/scss/index.scss';

const app = createApp(App);

app.use(router);

app.mount('#app');
